import { useEffect, useState } from "react";
import axios from "axios";
import "bulma/css/bulma.min.css";
import {
  Box,
  Button,
  Container,
  Heading,
  Section,
  Table,
} from "react-bulma-components";
import useToken from "../auth/UseToken";
import AdminNav from "../components/AdminNav";
import RemoveToken from "../auth/RemoveToken";
import StatusNotification from "../components/StatusNotification";

function AdminApplicants() {
  const [token] = useToken();

  // Existing applicants
  const [applicantList, setApplicantList] = useState({ applicants: [] });

  useEffect(() => {
    async function getApplicants() {
      try {
        const { data: applicants } = await axios.get("/api/admins/applicants", {
          headers: { Authorization: "Bearer " + token },
        });
        if (applicants) {
          setApplicantList({ applicants });
        }
      } catch (e) {
        console.log(e); // Remove later
        if (e.response) {
          if (e.response.status === 401) {
            RemoveToken();
          }
        } else {
          console.log(e); // Send error to BE?
        }
      }
    }

    getApplicants();
  }, [token]);

  return (
    <div>
      <AdminNav />
      <StatusNotification />
      <Container>
        <Section>
          <Heading>Applicants</Heading>
          <Container>
            <Box>
              <Heading subtitle>Existing Applicants</Heading>
              <Table size="fullwidth">
                <thead>
                  <tr>
                    <th>Created</th>
                    <th>First Name</th>
                    <th>Middle Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  {applicantList.applicants.map(applicant => {
                    return (
                      <tr>
                        <td>{applicant.created_pretty}</td>
                        <td>{applicant.first_name}</td>
                        <td>{applicant.middle_name}</td>
                        <td>{applicant.last_name}</td>
                        <td>{applicant.email}</td>
                        <td>{applicant.phone}</td>
                        <td>
                          <Button
                            renderAs="a"
                            href={"/admin/applicants/" + applicant.id}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Box>
          </Container>
        </Section>
      </Container>
    </div>
  );
}

export default AdminApplicants;
